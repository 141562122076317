<template>
	<div>
        <v-container fluid class="grid-list-xl pt-3 mt-12">
            <v-row class="align-center justify-center">
                <!-- 策略表格 -->
                <v-col cols="12" md="10" sm="10" v-if="responsive == 'PC'" class="mb-5">
                    <v-card tile class="px-4 pa-5">
                        <v-row>
                            <v-col cols="12">
                                <span class="fs-20 font-weight-bold mb-3">{{ $t('strategy.strategy') }}</span>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table :headers="strategyHeaders" hide-default-footer dense class="border pt-2">
                                    <template v-slot:top>
                                      <div class="d-custom-flex">
                                        <v-col cols="2" class="pa-0">
                                            <v-select v-model="selectedStrategy" :items="orderStrategies" dense flat solo hide-details :label="$t('strategy.strategy')"></v-select>
                                        </v-col>
                                        <v-divider class="mx-2" inset vertical></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" small tile dark class="mr-2" @click="addStrategyDialog = true">{{ $t('strategy.new') }}</v-btn>
                                      </div>
                                      <v-divider></v-divider>
                                    </template>
                                    <template v-slot:body>
                                        <tbody>
                                          <tr v-for="item in strategies" :key="item.id">
                                            <td width="20%" :style="{color : item.color}">{{ item.name }}</td>
                                            <td width="20%" :style="{color : item.color}">
                                                <v-btn tile x-small :color="item.color" @click="showColorPickerDialog(item)"></v-btn>
                                            </td>
                                            <td width="30%">{{ item.description != null ? item.description : $t('strategy.none') }}</td>
                                            <td>{{ formatDate(item.timestamp) }}</td>
                                            <td>
                                                <v-icon size="20" class="ml-2" @click="showEditStrategyDialog(item)" :title="$t('common.edit')">mdi-pencil</v-icon>
                                                <v-icon size="20" class="ml-2" @click="deleteStrategyId = item.id;deleteStrategyDialog = true" :title="$t('common.delete')">mdi-delete</v-icon>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </template>
                                    <template v-slot:footer>
                                        <div class="text-center py-3" v-if="strategiesLoading">
                                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                        </div>
                                        <div class="text-center py-3" v-if="!strategiesLoading && strategies.length == 0">
                                            {{ $t('common.noDataAvailable') }}
                                        </div>
                                    </template>
                                  </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- RK策略配置Config -->
                <v-col cols="12" md="10" sm="10" v-if="responsive == 'PC' && selectedStrategy == 'RK'" class="mb-5">
                    <v-card tile class="px-4 pa-5">
                        <v-row>
                            <v-col cols="12">
                                <span class="fs-20 font-weight-bold mb-3">{{ $t('strategy.renkoConfig') }}</span>
                            </v-col>
                            <v-col cols="12">
                                <v-data-table :headers="renkoConfigHeaders" hide-default-footer dense class="border pt-2" :items-per-page="pageSize">
                                    <template v-slot:top>
                                      <div class="d-custom-flex">
                                        <v-col cols="2" class="pa-0">
                                            <v-select v-model="selectedRenkoStrategyName" :items="renkoStrategyNames" dense flat solo hide-details :label="$t('strategy.name')" clearable></v-select>
                                        </v-col>
                                        <v-divider class="mx-2" inset vertical></v-divider>
                                        <v-col cols="2" class="pa-0">
                                            <v-select v-model="selectedConfigCurrency" :items="currencies" dense flat solo hide-details :label="$t('strategy.currency')" clearable></v-select>
                                        </v-col>
                                        <v-divider class="mx-2" inset vertical></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" small tile dark class="mr-2" @click="addRenkoConfigDialog = true">{{ $t('strategy.new') }}</v-btn>
                                      </div>
                                      <v-divider></v-divider>
                                    </template>
                                    <template v-slot:body>
                                        <tbody>
                                          <tr v-for="item in renkoConfigs" :key="item.id">
                                            <td>{{ item.currency }}</td>
                                            <td class="text-right">{{ formatNum(item.size.toFixed(getDecimalPlace(item.currency))) }}</td>
                                            <td class="text-right">{{ item.timeInterval }}</td>
                                            <td class="text-right">{{ formatDateTime(item.startTimestamp) }}</td>
                                            <td></td>
                                            <td>{{ formatDate(item.timestamp) }}</td>
                                            <td>
                                                <v-icon size="20" class="ml-2" @click="showEditRenkoConfigDialog(item)" :title="$t('common.edit')">mdi-pencil</v-icon>
                                                <v-icon size="20" class="ml-2" @click="deleteRenkoConfigId = item.id;deleteRenkoConfigDialog = true" :title="$t('common.delete')">mdi-delete</v-icon>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </template>
                                    <template v-slot:footer>
                                        <div class="text-center py-3" v-if="renkoConfigsLoading">
                                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                        </div>
                                        <div class="text-center py-3" v-if="!renkoConfigsLoading && renkoConfigs.length == 0">
                                            {{ $t('common.noDataAvailable') }}
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                            <v-col cols="auto" v-show="renkoConfigs.length > 0">
                                <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="renkoConfigsPageNo"
                              :length="renkoConfigsTotalPage" :total-visible="10" @input="getRenkoConfigs"></v-pagination>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- 使用Renko策略自动管理订单 -->
                <v-col cols="12" md="10" sm="10" xs="6" v-if="responsive == 'PC' && selectedStrategy == 'RK'" class="mb-5">      
                    <v-card tile class="px-4 pa-5">
                        <v-row>
                            <v-col cols="12">
                                <span class="fs-20 font-weight-bold mb-3">{{$t("batchOrder.useRenkoStrategyTitle")}}</span>
                                <span class="float-right mr-12">
                                    <v-btn depressed light tile :class="[useRenko ? 'lightred' : 'grey']" :loading="useRenkoLoading" @click="setUseRenko">
                                        {{ useRenko ? $t('batchOrder.enabled') : $t('batchOrder.disabled') }}
                                    </v-btn>
                                </span>
                            </v-col>
                            <v-col cols="12">
                                <div>{{$t("batchOrder.useRenkoStrategyMessage")}}</div>
                            </v-col>
                        </v-row>
                  </v-card>
                </v-col>
                <!-- Renko更新历史记录 -->
                <v-col cols="12" md="10" sm="10" v-if="responsive == 'PC' && selectedStrategy == 'RK'" mb-5>
                  <v-card tile class="px-4 pa-5">
                    <v-row>
                        <v-col cols="12">
                            <span class="fs-20 font-weight-bold mb-3">{{$t("batchOrder.updateHistory")}}</span>
                        </v-col>
                        <v-col cols="12">
                          <v-data-table dense class="border pt-2" :headers="updateHistoriesHeaders" hide-default-footer :items-per-page="pageSize">
                            <template v-slot:top>
                              <div class="d-custom-flex">
                                <v-col cols="2" class="pa-0">
                                    <v-select v-model="updateHistoriesSearch.strategy" :items="renkoStrategyNames" dense flat solo hide-details clearable :label="$t('strategy.name')"></v-select>
                                </v-col>
                                <v-divider class="mx-2" inset vertical></v-divider>
                                <v-col cols="2" class="pa-0">
                                    <v-select v-model="updateHistoriesSearch.currency" :items="currencies" dense flat solo hide-details clearable :label="$t('strategy.currency')"></v-select>
                                </v-col>
                                <v-divider class="mx-2" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                              </div>
                              <v-divider></v-divider>
                            </template>
                            <template v-slot:body>
                                <tbody>
                                    <tr v-for="item in updateHistories" :key="item.id">
                                        <td>{{ item.tradingCategory.toUpperCase() }}</td>
                                        <td>{{ item.strategy }}</td>
                                        <td>{{ item.currency }}</td>
                                        <td>
                                          <span class="grey--text" v-show="item.trend == 'no'">{{ $t('batchOrder.noNewBrick') }}</span>
                                          <span class="green--text" v-show="item.trend == 'up'">{{ $t('batchOrder.newGreenBrick') }}</span>
                                          <span class="red--text" v-show="item.trend == 'down'">{{ $t('batchOrder.newRedBrick') }}</span>
                                        </td>
                                        <td class="text-right">
                                          <span>{{ item.brickQuantity }}</span>
                                        </td>
                                        <td class="text-right">
                                          <span>{{ formatDateTime(item.timestamp) }}</span>
                                        </td>
                                        <td>
                                          <span class="ml-5" v-time="item.timestamp"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                            <template v-slot:footer>
                                <div class="text-center py-3" v-if="updateHistoriesLoading">
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </div>
                                <div class="text-center py-3" v-if="!updateHistoriesLoading && updateHistories.length == 0">
                                    {{ $t('common.noDataAvailable') }}
                                </div>
                            </template>
                          </v-data-table>
                          <div class="ml-2 ma-4 float-left" v-if="updateHistoriesTotalElements > 0">
                            <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="updateHistoriesPageNo"
                              :length="updateHistoriesTotalPage" :total-visible="15" @input="getRenkoUpdateHistories"></v-pagination>
                          </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <!-- 手机版 -->
                <!-- 策略 -->
                <v-col cols="12" md="10" sm="10" xs="6" v-if="responsive == 'mobile'">
                    <v-card tile class="px-2 pa-5">
                        <v-col cols="12">
                            <span class="fs-20 font-weight-bold mb-3">{{ $t('strategy.strategy') }}</span>
                        </v-col>
                        <v-row class="ml-0">
                            <v-col cols="6">
                            <v-select outlined v-model="selectedStrategy" :items="orderStrategies" dense flat solo hide-details :label="$t('strategy.strategy')"></v-select>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="3" class="align-self-sm-center mt-2">
                                <v-btn color="primary" small tile dark class="mr-2" @click="addStrategyDialog = true">{{ $t('strategy.new') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-col v-for="item in strategies" :key="item.id" class="py-0">
                            <v-card tile flat class="mt-2" outlined>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.name")}}</span>
                                        <span class="float-right" :style="{color : item.color}">{{ item.name }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.color")}}</span>
                                        <span class="float-right" :style="{color : item.color}">
                                                <v-btn tile x-small :color="item.color" @click="showColorPickerDialog(item)"></v-btn>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.description")}}</span>
                                        <span class="float-right" >{{ item.description != null ? item.description : $t('strategy.none') }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.createTime")}}</span>
                                        <span class="float-right" >{{ formatDate(item.timestamp) }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.actions")}}</span>
                                        <span class="float-right">
                                            <v-icon size="20" class="ml-2" @click="showEditStrategyDialog(item)" :title="$t('common.edit')">mdi-pencil</v-icon>
                                            <v-icon size="20" class="ml-2" @click="deleteStrategyId = item.id;deleteStrategyDialog = true" :title="$t('common.delete')">mdi-delete</v-icon>
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col>
                            <div class="text-center py-3" v-if="strategiesLoading">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                            <div class="text-center py-3" v-if="!strategiesLoading && strategies.length == 0">
                                {{ $t('common.noDataAvailable') }}
                            </div>
                        </v-col>
                    </v-card>
                </v-col>
                <!-- RK策略配置Config -->
                <v-col cols="12" md="10" sm="10" xs="6" v-if="responsive == 'mobile' && selectedStrategy == 'RK'">
                    <v-card tile class="px-2 pa-5">
                        <v-col cols="12">
                            <span class="fs-20 font-weight-bold mb-3">{{ $t('strategy.renkoConfig') }}</span>
                        </v-col>
                        <v-row class="ml-0">
                            <v-col cols="4">
                                <v-select v-model="selectedRenkoStrategyName" :items="renkoStrategyNames" outlined dense flat solo hide-details clearable :label="$t('strategy.name')"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-select v-model="selectedConfigCurrency" :items="currencies" outlined dense flat solo hide-details clearable :label="$t('strategy.currency')"></v-select>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="align-self-sm-center mt-2 ">
                                <v-btn color="primary" small tile dark class="mr-2" @click="addRenkoConfigDialog = true">{{ $t('strategy.new') }}</v-btn>
                            </v-col>
                        </v-row>
                        <v-col v-for="item in renkoConfigs" :key="item.id" class="py-0">
                            <v-card tile flat class="mt-2" outlined>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.currency")}}</span>
                                        <span class="float-right" >{{ item.currency }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.size")}}</span>
                                        <span class="float-right" >{{ formatNum(item.size.toFixed(getDecimalPlace(item.currency))) }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.timeInterval")}}</span>
                                        <span class="float-right" >{{ item.timeInterval }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.startTime")}}</span>
                                        <span class="float-right" >{{ formatDateTime(item.startTimestamp) }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.createTime")}}</span>
                                        <span class="float-right" >{{ formatDate(item.timestamp) }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.actions")}}</span>
                                        <span class="float-right">
                                            <v-icon size="20" class="ml-2" @click="showEditRenkoConfigDialog(item)" :title="$t('common.edit')">mdi-pencil</v-icon>
                                            <v-icon size="20" class="ml-2" @click="deleteRenkoConfigId = item.id;deleteRenkoConfigDialog = true" :title="$t('common.delete')">mdi-delete</v-icon>
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col>
                            <div class="text-center py-3" v-if="renkoConfigsLoading">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                            <div class="text-center py-3" v-if="!renkoConfigsLoading && renkoConfigs.length == 0">
                                {{ $t('common.noDataAvailable') }}
                            </div>
                        </v-col>
                    </v-card>
                </v-col>
                <!-- 使用Renko策略自动管理订单 -->
                <v-col cols="12" md="10" sm="10" xs="6" v-if="responsive == 'mobile' && selectedStrategy == 'RK'">
                    <v-card tile class="px-4 pa-5">
                        <v-row >
                        <v-col cols="12">
                            <v-row no-gutters>
                                <v-col cols="8">
                                    <span class="fs-20 font-weight-bold mb-3">{{$t("batchOrder.useRenkoStrategyTitle")}}</span>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="3" class="mr-2">
                                    <v-btn depressed  tile :class="[useRenko ? 'lightred' : 'grey']" :loading="useRenkoLoading" @click="setUseRenko">
                                        {{ useRenko ? $t('batchOrder.enabled') : $t('batchOrder.disabled') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <div>{{$t("batchOrder.useRenkoStrategyMessage")}}</div>
                        </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <!-- Renko更新历史记录 -->
                <v-col cols="12" md="10" sm="10" xs="6" v-if="responsive == 'mobile' && selectedStrategy == 'RK'">
                    <v-card tile class="px-2 pa-5">
                        <v-col cols="12">
                            <span class="fs-20 font-weight-bold mb-3">{{ $t('batchOrder.updateHistory') }}</span>
                        </v-col>
                        <v-row class="ml-0">
                            <v-col cols="5">
                                 <v-select outlined class="fs-12" v-model="updateHistoriesSearch.strategy" :items="renkoStrategyNames" dense flat solo hide-details clearable :label="$t('strategy.name')"></v-select>
                            </v-col>
                            <v-divider class="mx-2 my-3" inset vertical></v-divider>
                            <v-col cols="5">
                                <v-select outlined class="fs-12" v-model="updateHistoriesSearch.currency" :items="currencies" dense flat solo hide-details clearable :label="$t('strategy.currency')"></v-select>
                            </v-col>
                        </v-row>
                        <v-col v-for="item in updateHistories" :key="item.id" class="py-0">
                            <v-card tile flat class="mt-2" outlined>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("common.tradingCategory")}}</span>
                                        <span class="float-right" >{{ item.tradingCategory.toUpperCase() }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.strategy")}}</span>
                                        <span class="float-right" >{{ item.strategy }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.currency")}}</span>
                                        <span class="float-right" >{{ item.currency }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.trend")}}</span>
                                        <span class="float-right" >
                                          <span class="grey--text" v-show="item.trend == 'no'">{{ $t('batchOrder.noNewBrick') }}</span>
                                          <span class="green--text" v-show="item.trend == 'up'">{{ $t('batchOrder.newGreenBrick') }}</span>
                                          <span class="red--text" v-show="item.trend == 'down'">{{ $t('batchOrder.newRedBrick') }}</span>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.brickQuantity")}}</span>
                                        <span class="float-right" >{{ item.brickQuantity }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1">{{$t("strategy.editTime")}}</span>
                                        <span class="float-right" >{{ formatDateTime(item.timestamp) }}</span>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-3" no-gutters>
                                    <v-col cols="12" no-gutters>
                                        <span class="grey--text text--lighten-1"></span>
                                        <span class="float-right" v-time="item.timestamp"></span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col>
                            <div class="text-center py-3" v-if="updateHistoriesLoading">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </div>
                            <div class="text-center py-3" v-if="!updateHistoriesLoading && updateHistories.length == 0">
                                {{ $t('common.noDataAvailable') }}
                            </div>
                            <div v-if="updateHistoriesTotalElements > 0">
                            <v-pagination color="lightred" circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="updateHistoriesPageNo"
                              :length="updateHistoriesTotalPage" :total-visible="15" @input="getRenkoUpdateHistories"></v-pagination>
                          </div>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col cols="12" md="10" sm="10" xs="6" class="mb-5 mt-3" v-if="selectedStrategy == 'RK'">
                  <v-card tile class="px-4 pa-5">
                    <div>
                      <h3 class="mb-3">{{$t("batchOrder.batchOrder")}}</h3>
                      <li>{{ $t("batchOrder.brickParamNote") }}</li>
                      <!-- <li>{{ $t("batchOrder.selectNote") }}</li> -->
                      <li>{{ $t("batchOrder.undoneNote") }}</li>
                      <li>{{ $t("batchOrder.pendingOrdersNote") }}</li>
                      <li>{{ $t("batchOrder.openPositionsOrdersNote") }}</li>
                      <li>{{ $t("batchOrder.partiallyFilledNote") }}</li>
                      <li>{{ $t("batchOrder.effectiveTimeNote") }}</li>
                      <!-- <li>{{ $t("batchOrder.rkStrategyNote") }}</li> -->
                      <!-- <li>{{ $t("batchOrder.ppOrCppStrategyNote") }}</li> -->
                    </div>
                  </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- 新增策略 -->
        <v-dialog v-model="addStrategyDialog" persistent width="450">
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text">{{ $t('strategy.newStrategy') }}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="addStrategyDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="pb-0">
                <v-form ref="strategyForm">
                    <v-row dense>
                        <v-col cols="6">
                            <v-select v-model="strategyForm.strategy" :label="$t('strategy.strategy')" :items="orderStrategies" dense outlined></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="strategyForm.name" :label="$t('strategy.name')" dense outlined clearable></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="strategyForm.description" :label="$t('strategy.description')" dense outlined clearable></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="strategyForm.timeInterval" :label="$t('strategy.timeInterval')" type="number" min="0" step="1" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="3" class="align-self-sm-center">
                            <span>{{ $t('strategy.startTime') + ':' }}</span>
                        </v-col>
                        <v-col cols="9">
                            <datetime input-id="strategyFormDatetime" v-model="addStrategyStartDatetime" class="timeBg" type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :label="$t('strategy.startTime')" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}" auto :min-datetime="minStartDatetime" :max-datetime="currentDatetime"></datetime>
                        </v-col>
                        <v-col cols="12">
                            <div class="fs-12 mt-5">
                                {{ $t('strategy.newStrategyTip') }}
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions class="mx-5 pb-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="grey white--text" @click="addStrategyDialog = false">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn small tile class="blue white--text" :loading="addStrategyLoading" @click="submitAddStrategy">
                  {{ $t('common.ok') }}
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 删除策略 -->
        <v-dialog v-model="deleteStrategyDialog" persistent width="450">
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text">{{ $t('strategy.deleteStrategy') }}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="deleteStrategyDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-actions class="mx-5 pb-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="grey white--text" @click="deleteStrategyDialog = false">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn small tile class="blue white--text" :loading="deleteStrategyLoading" @click="submitDeleteStrategy">
                  {{ $t('common.ok') }}
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 新增RenkoConfig -->
        <v-dialog v-model="addRenkoConfigDialog" persistent width="450">
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text">{{ $t('strategy.newRenkoConfig') }}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="addRenkoConfigDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="pb-0">
                <v-form ref="renkoConfigForm">
                    <v-row dense>
                        <v-col cols="6">
                            <v-select v-model="renkoConfigForm.strategyName" :label="$t('strategy.strategy')" :items="renkoStrategyNames" dense outlined></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="renkoConfigForm.currency" :label="$t('strategy.currency')" :items="currencies" dense outlined></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="renkoConfigForm.size" :label="$t('strategy.size')" type="number" min="0" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="renkoConfigForm.timeInterval" :label="$t('strategy.timeInterval')" type="number" min="0" step="1" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="3" class="align-self-sm-center">
                            <span>{{ $t('strategy.startTime') + ':' }}</span>
                        </v-col>
                        <v-col cols="8">
                            <datetime input-id="renkoConfigFormDatetime" v-model="addRenkoConfigStartDatetime" class="timeBg" type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :label="$t('strategy.startTime')" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}" auto :min-datetime="minStartDatetime" :max-datetime="currentDatetime"></datetime>
                        </v-col>
                        <v-col cols="1" class="align-self-sm-center">
                            <v-icon size="24" :title="$t('strategy.timeCalculator')" @click="showTimeCalculatorDialog">mdi-calculator</v-icon>
                        </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions class="mx-5 pb-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="grey white--text" @click="addRenkoConfigDialog = false">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn small tile class="blue white--text" :loading="addRenkoConfigLoading" @click="submitAddRenkoConfig">
                  {{ $t('common.ok') }}
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 删除RenkoConfig -->
        <v-dialog v-model="deleteRenkoConfigDialog" persistent width="450">
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text">{{ $t('strategy.deleteRenkoConfig') }}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="deleteRenkoConfigDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-actions class="mx-5 pb-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="grey white--text" @click="deleteRenkoConfigDialog = false">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn small tile class="blue white--text" :loading="deleteRenkoConfigLoading" @click="submitDeleteRenkoConfig">
                  {{ $t('common.ok') }}
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 时间计算器 -->
        <v-dialog v-model="timeCalculatorDialog" persistent width="450">
            <v-card>
                <v-card-title class="grey lighten-2">
                    <span class="black--text">{{ $t('strategy.estimatedExecutionTime') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn light icon @click="timeCalculatorDialog = false; renkoExecutionTimeList = []">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container class="pb-0">
                    <v-row class="align-center justify-center">
                        <v-col cols="8" class="text-center">
                            <div v-for="(time, index) in renkoExecutionTimeList" :key="time">
                              <div class="fs-16 my-1" :disabled="time > currentTimestamp" :class="time <= currentTimestamp ? 'green--text pointer' : 'grey--text'" @click="changeStartTimestamp(time)">{{ formatDateTime(time) }}</div>
                            </div>
                        </v-col>
                        <v-col cols="8" class="d-custom-flex">
                            <v-icon :disabled="renkoExecutionTimeList[0] - renkoConfigForm.timeInterval * 3600000 <= 1586649600000" @click="calcRenkoExecutionTime('-')">mdi-chevron-left</v-icon>
                            <v-spacer></v-spacer>
                            <v-icon @click="calcRenkoExecutionTime('+')">mdi-chevron-right</v-icon>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- 颜色选择器弹窗 -->
        <v-dialog v-model="colorPickerDialog" persistent width="360">
            <v-card>
                <v-card-title class="grey lighten-2">
                    <span class="black--text">{{ $t('strategy.colorPicker') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn light icon @click="colorPickerDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-container class="pb-0">
                    <v-row class="align-center justify-center pb-5">
                        <!-- v-if是为了刷新组件 -->
                        <color-picker v-if="colorPickerDialog" :color="color" @changeColor="changeColor" style="width: 250px"></color-picker>
                    </v-row>
                </v-container>
                <v-card-actions class="mx-5 pb-5">
                    <v-spacer></v-spacer>
                    <v-btn small tile class="blue white--text mr-7" @click="submitChangeColor">
                      {{ $t('common.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 编辑RenkoConfig -->
        <v-dialog v-model="editRenkoConfigDialog" persistent width="450">
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text">{{ $t('strategy.editRenkoConfig') }}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="editRenkoConfigDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="pb-0">
                <v-form ref="editRenkoConfigForm">
                    <v-row dense>
                        <v-col cols="6">
                            <v-select v-model="editRenkoConfigForm.strategyName" :label="$t('strategy.strategy')" :items="renkoStrategyNames" dense outlined disabled></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select v-model="editRenkoConfigForm.currency" :label="$t('strategy.currency')" :items="currencies" dense outlined disabled></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="editRenkoConfigForm.size" :label="$t('strategy.size')" type="number" min="0" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="editRenkoConfigForm.timeInterval" :label="$t('strategy.timeInterval')" type="number" min="0" step="1" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="3" class="align-self-sm-center">
                            <span>{{ $t('strategy.startTime') + ':' }}</span>
                        </v-col>
                        <v-col cols="9">
                            <datetime input-id="editRenkoConfigFormDatetime" v-model="editRenkoConfigStartDatetime" class="timeBg" type="datetime" :week-start="7" format="MM/dd/yyyy HH:mm:ss" :zone="currentTimeZone" :label="$t('strategy.startTime')" :phrases="{ok: $t('common.ok'), cancel: $t('common.cancel')}" auto :min-datetime="minStartDatetime" :max-datetime="currentDatetime"></datetime>
                        </v-col>
                        <!-- <v-col cols="1" class="align-self-sm-center">
                            <v-icon size="24" :title="$t('strategy.timeCalculator')" @click="showTimeCalculatorDialog">mdi-calculator</v-icon>
                        </v-col> -->
                    </v-row>
                </v-form>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions class="mx-5 pb-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="grey white--text" @click="editRenkoConfigDialog = false">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn small tile class="blue white--text" :loading="editRenkoConfigLoading" @click="submitEditRenkoConfig">
                  {{ $t('common.ok') }}
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 编辑策略 -->
        <v-dialog v-model="editStrategyDialog" persistent width="450">
            <v-card>
              <v-card-title class="grey lighten-2">
                <span class="black--text">{{ $t('strategy.editStrategy') }}</span>
                <v-spacer></v-spacer>
                <v-btn light icon @click="editStrategyDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-container class="pb-0">
                <v-form ref="editStrategyForm">
                    <v-row dense>
                        <v-col cols="6">
                            <v-select v-model="editStrategyForm.strategy" :label="$t('strategy.strategy')" :items="orderStrategies" dense outlined disabled></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="editStrategyForm.name" :label="$t('strategy.name')" dense outlined clearable disabled></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="editStrategyForm.description" :label="$t('strategy.description')" dense outlined clearable></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions class="mx-5 pb-5">
                <v-spacer></v-spacer>
                <v-btn small tile class="grey white--text" @click="editStrategyDialog = false">
                  {{ $t('common.cancel') }}
                </v-btn>
                <v-btn small tile class="blue white--text" :loading="editStrategyLoading" @click="submitEditStrategy">
                  {{ $t('common.ok') }}
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    <mobile-btn></mobile-btn>
	</div>
</template>
<style>
	.theme--dark.v-pagination .v-pagination__item--active {
	    color: #212121!important;
	}
</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import InfiniteLoading from 'vue-infinite-loading';
    import ColorPicker from '@caohenghu/vue-colorpicker';
    import MobileBtn from "@/components/common/MobileBtn";
    export default {
        data(){
            return {
                strategyHeaders: [
                    { text: this.$t('strategy.name'), sortable: false },
                    { text: this.$t('strategy.color'), sortable: false },
                    { text: this.$t('strategy.description'), sortable: false },
                    { text: this.$t('strategy.createTime'), sortable: false },
                    { text: this.$t('strategy.actions'), sortable: false },
                ],
                renkoConfigHeaders: [
                    { text: this.$t('strategy.currency'), sortable: false },
                    { text: this.$t('strategy.size'), align: 'end', sortable: false },
                    { text: this.$t('strategy.timeInterval'), align: 'end', sortable: false },
                    { text: this.$t('strategy.startTime'), align: 'end', sortable: false },
                    { text: '', sortable: false },
                    { text: this.$t('strategy.createTime'), sortable: false },
                    { text: this.$t('strategy.actions'), sortable: false }
                ],
                // 策略集合加载中
                strategiesLoading: false,
                // 策略集合
                strategies: [],
                // 选择的策略
                selectedStrategy: 'RK',
                // 选择的Renko策略名
                selectedRenkoStrategyName: null,
                // 策略配置筛选的条件币
                selectedConfigCurrency: null,
                // Renko策略名称数组
                renkoStrategyNames: [],
                // Renko配置集合加载中
                renkoConfigsLoading: false,
                // Renko配置集合
                renkoConfigs: [],
                pageSize: 15,
                // renkoConfigs页码
                renkoConfigsPageNo: 1,
                // renkoConfigs总页数
                renkoConfigsTotalPage: 0,
                // 新增策略的弹框
                addStrategyDialog: false,
                // 新增策略的加载中
                addStrategyLoading: false,
                // 新增策略的开始时间
                addStrategyStartDatetime: '2020-04-12T00:00:00.000Z',
                // 新增策略表单
                strategyForm: {
                    strategy: null,
                    name: null,
                    description: null,
                    timeInterval: 0,
                    startTimestamp: 0
                },
                // 删除策略的弹框
                deleteStrategyDialog: false,
                // 删除策略的加载中
                deleteStrategyLoading: false,
                // 删除策略的主键
                deleteStrategyId: null,
                // 新增renkoConfig的弹窗
                addRenkoConfigDialog: false,
                // 新增renkoConfig的加载中
                addRenkoConfigLoading: false,
                // 新增策略表单
                renkoConfigForm: {
                    strategyName: null,
                    currency: null,
                    size: 0,
                    timeInterval: 0,
                    startTimestamp: 0
                },
                // 新增Renko配置的开始时间
                addRenkoConfigStartDatetime: '2020-04-12T00:00:00.000Z',
                // 删除RenkoConfig的弹框
                deleteRenkoConfigDialog: false,
                // 删除RenkoConfig的加载中
                deleteRenkoConfigLoading: false,
                // 删除RenkoConfig的主键
                deleteRenkoConfigId: null,
                // 时间计算器弹框
                timeCalculatorDialog: false,
                // 前一个Renko执行时间集合
                renkoExecutionTimeList: [],
                // 当前时间戳
                currentTimestamp: +new Date(),
                // 使用Renko策略
                useRenko: false,
                // 使用Renko策略加载中
                useRenkoLoading: false,
                updateHistories: [],
                updateHistoriesPageNo: 1,
                updateHistoriesTotalElements: 0,
                updateHistoriesTotalPage: 0,
                updateHistoriesLoading: false,
                updateHistoriesSearch: {
                    strategy: null,
                    currency: null
                },
                updateHistoriesHeaders: [
                    { text: this.$t('common.tradingCategory'), sortable: false },
                    { text: this.$t('strategy.strategy'), sortable: false },
                    { text: this.$t('strategy.currency') , sortable: false },
                    { text: this.$t('strategy.trend') , sortable: false },
                    { text: this.$t('strategy.brickQuantity') , sortable: false, align: 'end' },
                    { text: this.$t('strategy.editTime'), sortable: false, align: 'end' },
                    { text: '', sortable: false, align: 'end' }
                ],
                // 调色板选中的颜色
                color: '#9e9e9e',
                // 颜色选择器弹窗
                colorPickerDialog: false,
                // 改变颜色的策略
                changeColorStrategy: null,
                // 编辑Renko配置的开始时间
                editRenkoConfigStartDatetime: '2020-04-12T00:00:00.000Z',
                // 编辑Renko配置弹窗
                editRenkoConfigDialog: false,
                // 编辑Renko配置加载中
                editRenkoConfigLoading: false,
                // 编辑的Renko配置对象表单
                editRenkoConfigForm: {},
                // 编辑策略的弹框
                editStrategyDialog: false,
                // 编辑策略的加载中
                editStrategyLoading: false,
                // 编辑策略表单
                editStrategyForm: {},
            }
        },
        computed: {
            ...mapGetters(['user', 'responsive', 'time', 'orderStrategies', 'currencies', 'spotSymbolInfos', 'swapUsdtSymbolInfos']),
            currentTimeZone(){
              if ('UTCTime' == this.time) {
                return 'UTC';
              } else {
                return 'local';
              }
            },
            minStartDatetime: {
              get: function () {
                  return formatISO8601Time(new Date("2020-04-12"));
              },
              set: function (newValue) {
                  return newValue;
              }
            },
            // 当前时间
            currentDatetime: {
              get: function () {
                  return formatISO8601Time(+new Date());
              },
              set: function (newValue) {
                  return newValue;
              }
            },
        },
        components: { InfiniteLoading, ColorPicker,MobileBtn },
        created(){
            this.isLogin();
            this.getStrategies();
            this.getRenkoSreategyNames();
            this.getRenkoUpdateHistories(this.updateHistoriesPageNo);
            this.isUseRenko();
        },
        mounted(){

        },
        watch:{
            selectedStrategy(newVal, oldVal){
                if (newVal) {
                    this.getStrategies();
                }
            },
            selectedRenkoStrategyName(newVal, oldVal){
                this.renkoConfigsPageNo = 1;
                // if (newVal) {
                //     this.getRenkoConfigs(this.renkoConfigsPageNo);
                // }
                this.getRenkoConfigs(this.renkoConfigsPageNo);
            },
            selectedConfigCurrency(){
                this.renkoConfigsPageNo = 1;
                this.getRenkoConfigs(this.renkoConfigsPageNo);
            },
            addStrategyStartDatetime(newVal, oldVal){
                if (newVal) {
                    this.strategyForm.startTimestamp = parseISO8601ToTimestamp(this.addStrategyStartDatetime);
                }
            },
            addRenkoConfigStartDatetime(newVal, oldVal){
                if (newVal) {
                    this.renkoConfigForm.startTimestamp = parseISO8601ToTimestamp(this.addRenkoConfigStartDatetime);
                }
            },
            editRenkoConfigStartDatetime(newVal, oldVal){
                if (newVal) {
                    this.editRenkoConfigForm.startTimestamp = parseISO8601ToTimestamp(this.editRenkoConfigStartDatetime);
                }
            },
            // 监听更新历史搜索
            updateHistoriesSearch: {
                handler(newObject, oldObject) {
                  this.updateHistoriesPageNo = 1;
                  this.getRenkoUpdateHistories(this.updateHistoriesPageNo);
                },
                immediate: true,
                deep: true
            },
        },
        methods: {
            // 查询策略集合
            getStrategies(){
                this.strategiesLoading = true;
                this.strategies = [];
                let api = config.baseUrl + '/strategy/private/strategies?strategy=' + this.selectedStrategy;
                this.$http.get(api).then(response => {
                    this.strategiesLoading = false;
                    let data = response.data;
                    if (data.code == 200) {
                        this.strategies = data.result;
                    } else {
                        this.strategies = [];
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.strategiesLoading = false;
                    this.strategies = [];
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 查询Renko策略的名称集合
            getRenkoSreategyNames(){
                let api = config.baseUrl + '/strategy/private/strategies/name?strategy=RK';
                this.$http.get(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        this.renkoStrategyNames = data.result;
                        this.selectedRenkoStrategyName = this.renkoStrategyNames[0];
                    } else {
                        this.renkoStrategyNames = [];
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.renkoStrategyNames = [];
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 查询Renko配置集合
            getRenkoConfigs(pageNo){
                this.renkoConfigsLoading = true;
                this.renkoConfigs = [];
                let api = config.baseUrl + '/renko/config/private/configs?pageNo='+ (pageNo - 1) + '&pageSize=' + this.pageSize;
                if(this.selectedRenkoStrategyName){
                    api += '&name=' + this.selectedRenkoStrategyName;
                }
                if(this.selectedConfigCurrency){
                    api += '&currency=' + this.selectedConfigCurrency;
                }
                this.$http.get(api).then(response => {
                    let data = response.data;
                    this.renkoConfigsLoading = false;
                    if (data.code == 200) {
                        this.renkoConfigs = data.result.list;
                        this.renkoConfigsTotalPage = Math.ceil(data.result.totalElements/this.pageSize);
                    } else {
                        this.renkoConfigs = [];
                        this.renkoConfigsTotalPage = 0;
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.renkoConfigsLoading = false;
                    this.renkoConfigs = [];
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 提交添加策略
            submitAddStrategy(){
                this.addStrategyLoading = true;
                this.strategyForm.startTimestamp = parseISO8601ToTimestamp(this.addStrategyStartDatetime);
                let api = config.baseUrl + '/strategy/private/add';
                this.$http.post(api, this.strategyForm).then(response => {
                    let data = response.data;
                    this.addStrategyLoading = false;
                    if (data.code == 200) {
                        this.addStrategyDialog = false;
                        this.getStrategies();
                        this.resetForm('strategyForm');
                        if (this.selectedStrategy == 'RK') {
                            this.getRenkoSreategyNames();
                        }
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.addStrategyLoading = false;
                    this.addStrategyDialog = false;
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 删除策略
            submitDeleteStrategy(){
                this.deleteStrategyLoading = true;
                let api = config.baseUrl + '/strategy/private/delete?id=' + this.deleteStrategyId;
                this.$http.delete(api).then(response => {
                    let data = response.data;
                    this.deleteStrategyLoading = false;
                    this.deleteStrategyDialog = false;
                    if (data.code == 200) {
                        this.getStrategies();
                        if (this.selectedStrategy == 'RK') {
                            this.getRenkoSreategyNames();
                        }
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.deleteStrategyLoading = false;
                    this.deleteStrategyDialog = false;
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 新增RenkoConfig
            submitAddRenkoConfig(){
                this.addRenkoConfigLoading = true;
                this.renkoConfigForm.startTimestamp = parseISO8601ToTimestamp(this.addRenkoConfigStartDatetime);
                let api = config.baseUrl + '/renko/config/private/add';
                this.$http.post(api, this.renkoConfigForm).then(response => {
                    let data = response.data;
                    this.addRenkoConfigLoading = false;
                    if (data.code == 200) {
                        this.addRenkoConfigDialog = false;
                        this.getRenkoConfigs(this.renkoConfigsPageNo);
                        this.resetForm('renkoConfigForm');
                        this.addRenkoConfigStartDatetime = '2020-04-12T00:00:00.000Z';
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.addRenkoConfigLoading = false;
                    this.addRenkoConfigDialog = false;
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 删除RenkoConfig
            submitDeleteRenkoConfig(){
                this.deleteRenkoConfigLoading = true;
                let api = config.baseUrl + '/renko/config/private/delete?id=' + this.deleteRenkoConfigId;
                this.$http.delete(api).then(response => {
                    let data = response.data;
                    this.deleteRenkoConfigLoading = false;
                    this.deleteRenkoConfigDialog = false;
                    if (data.code == 200) {
                        this.getRenkoConfigs(this.renkoConfigsPageNo);
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.deleteRenkoConfigLoading = false;
                    this.deleteRenkoConfigDialog = false;
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 显示时间计算器
            showTimeCalculatorDialog(){
                this.currentTimestamp = +new Date();
                if (this.renkoConfigForm.timeInterval <= 0) {
                    this.$store.dispatch('snackbarMessageHandler', this.$t('strategy.invaildTimeInterval'));
                    return;
                }
                this.calcRenkoExecutionTime('+');
                this.timeCalculatorDialog = true;
            },
            // 计算Renko执行时间
            calcRenkoExecutionTime(operation){
                this.renkoConfigForm.startTimestamp = parseISO8601ToTimestamp(this.addRenkoConfigStartDatetime);
                let timestamp = this.renkoConfigForm.startTimestamp;
                let timeIntervalMillis = this.renkoConfigForm.timeInterval * 3600000;
                if (operation == '+') {
                    if (this.renkoExecutionTimeList.length > 0) {
                        timestamp = this.renkoExecutionTimeList[this.renkoExecutionTimeList.length - 1];
                    }
                    this.renkoExecutionTimeList = [];
                    for (let i = 0; i < 10; i++) {
                        timestamp += timeIntervalMillis;
                        this.renkoExecutionTimeList[i] = timestamp;
                    }
                } else if(operation == '-'){
                    if (this.renkoExecutionTimeList.length > 0) {
                        timestamp = this.renkoExecutionTimeList[0];
                    }
                    this.renkoExecutionTimeList = [];
                    for (let i = 0; i < 10; i++) {
                        timestamp -= timeIntervalMillis;
                        if (timestamp < 1586649600000) {
                            break;
                        }
                        this.renkoExecutionTimeList[i] = timestamp;
                    }
                    this.renkoExecutionTimeList.reverse();
                }
            },
            // 改变开始时间戳
            changeStartTimestamp(timestamp){
                if (timestamp >= 1586649600000 && timestamp <= this.currentTimestamp) {
                    this.renkoConfigForm.startTimestamp = timestamp;
                    this.addRenkoConfigStartDatetime = formatISO8601Time(timestamp);
                    this.timeCalculatorDialog = false;
                    this.renkoExecutionTimeList = [];
                } else {
                    this.$store.dispatch('snackbarMessageHandler', this.$t('strategy.invaildStartTime'));
                }
            },
            // 是否使用Renko
            isUseRenko(){
                let api = config.baseUrl + '/setting/private/renko/use';
                this.$http.get(api).then(response => {
                  let data = response.data;
                  if (data.code == 200) {
                    this.useRenko = data.result;
                  } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                  }
                }, error => {
                  this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 设置使用Renko
            setUseRenko(){
                let param = this.useRenko ? 0 : 1;
                let api = config.baseUrl + '/setting/private/renko/set?useRenko=' + param;
                this.useRenkoLoading = true;
                this.$http.put(api).then(response => {
                  this.useRenkoLoading = false;
                  let data = response.data;
                  if (data.code == 200) {
                    this.useRenko = data.result;
                  } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                  }
                }, error => {
                  this.useRenkoLoading = false;
                  this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 查询Renko更新历史集合
            getRenkoUpdateHistories(pageNo){
                this.updateHistoriesLoading = true;
                this.updateHistories = [];
                let api = config.baseUrl + '/renko/history/private/histories?pageNo=' + (pageNo -1) + '&pageSize=' + this.pageSize;
                if (this.updateHistoriesSearch.strategy) {
                    api = api + '&strategy=' + this.updateHistoriesSearch.strategy;
                }
                if (this.updateHistoriesSearch.currency) {
                    api = api + '&currency=' + this.updateHistoriesSearch.currency;
                }
                this.$http.get(api).then(response => {
                  let data = response.data;
                  this.updateHistoriesLoading = false;
                  if (data.code == 200) {
                    this.updateHistories = data.result.list;
                    this.updateHistoriesTotalElements = data.result.totalElements;
                    this.updateHistoriesTotalPage = Math.ceil(data.result.totalElements/this.pageSize);
                  } else {
                    this.updateHistories = [];
                    this.updateHistoriesTotalElements = 0;
                    this.updateHistoriesTotalPage = 0;
                  }
                }, error => {
                    this.updateHistoriesLoading = false;
                    this.updateHistories = [];
                    this.updateHistoriesTotalElements = 0;
                    this.updateHistoriesTotalPage = 0;
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 重置表单
            resetForm (formName) {
                this.$refs[formName].reset();
            },
            // 格式化时间
            formatDateTime(timestamp){
                if('UTCTime' == this.time){
                  return formatUTCTime(timestamp);
                }else{
                  return formatTime(timestamp);
                }
            },
            // 格式化日期
            formatDate(timestamp){
                return formatDate(timestamp, this.$i18n.locale);
            },
            // 格式化数字
            formatNum(num){
                return formatNumber(num);
            },
            // 获取小数位
            getDecimalPlace(currency){
                if (currency == null) {
                  return 8;
                }
                // 多判断几个交易所查询小数位，总有一些特殊的币查询不到
                // OKEX Spot
                let okexExchangeSpotSymbolInfos = this.spotSymbolInfos['okex'];
                for (let i = 0, len = okexExchangeSpotSymbolInfos.length; i < len; i++) {
                    if (okexExchangeSpotSymbolInfos[i].baseCurrency == currency) {
                        return okexExchangeSpotSymbolInfos[i].priceDecimalPlace;
                    }
                }
                // FTX Spot
                let ftxExchangeSpotSymbolInfos = this.spotSymbolInfos['ftx'];
                for (let i = 0, len = ftxExchangeSpotSymbolInfos.length; i < len; i++) {
                    if (ftxExchangeSpotSymbolInfos[i].baseCurrency == currency) {
                        return ftxExchangeSpotSymbolInfos[i].priceDecimalPlace;
                    }
                }
                // OKEX Swap USDT
                let okexSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos['okex'];
                for(let i = 0, len = okexSwapUsdtSymbolInfos.length; i < len; i++){
                  if (okexSwapUsdtSymbolInfos[i].baseCurrency == currency) {
                    return okexSwapUsdtSymbolInfos[i].priceDecimalPlace;
                  }
                }
                // FTX Swap USDT
                let ftxSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos['ftx'];
                for(let i = 0, len = ftxSwapUsdtSymbolInfos.length; i < len; i++){
                  if (ftxSwapUsdtSymbolInfos[i].baseCurrency == currency) {
                    return ftxSwapUsdtSymbolInfos[i].priceDecimalPlace;
                  }
                }
                return 8;
            },
            // 改变颜色
            changeColor(color) {
                if (color) {
                    this.color = color.hex;
                }
            },
            // 显示颜色选择器弹窗
            showColorPickerDialog(strategy){
                if (strategy) {
                    this.changeColorStrategy = strategy;
                    this.color = strategy.color;
                    this.colorPickerDialog = true;
                }
            },
            // 提交改变颜色请求
            submitChangeColor(){
                let api = config.baseUrl + '/strategy/private/color';
                this.colorPickerDialog = false;
                let param = {
                    id: this.changeColorStrategy.id,
                    color: this.color
                };
                this.$http.put(api, param).then(response => {
                  let data = response.data;
                  if (data.code == 200) {
                    this.changeColorStrategy.color = this.color;
                    // 刷新颜色缓存
                    this.getStrategyColors();
                  } else {
                    this.$store.dispatch('snackbarMessageHandler', data.message);
                  }
                }, error => {
                  this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 查询策略颜色
            getStrategyColors(){
                let api = config.baseUrl + '/strategy/private/colors';
                this.$http.get(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        this.$store.dispatch('strategyColorsHandler', data.result);
                    } else {
                        this.$store.dispatch('strategyColorsHandler', []);
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.$store.dispatch('strategyColorsHandler', []);
                });
            },
            // 显示编辑Renko配置弹窗
            showEditRenkoConfigDialog(renkoConfig){
                // 将对象序列化再解析目的是为了复制一个对象
                this.editRenkoConfigForm = JSON.parse(JSON.stringify(renkoConfig));
                this.editRenkoConfigStartDatetime = formatISO8601Time(this.editRenkoConfigForm.startTimestamp);
                this.editRenkoConfigDialog = true;
            },
            // 编辑RenkoConfig
            submitEditRenkoConfig(){
                this.editRenkoConfigLoading = true;
                this.editRenkoConfigForm.startTimestamp = parseISO8601ToTimestamp(this.editRenkoConfigStartDatetime);
                let api = config.baseUrl + '/renko/config/private/edit';
                this.$http.put(api, this.editRenkoConfigForm).then(response => {
                    let data = response.data;
                    this.editRenkoConfigLoading = false;
                    if (data.code == 200) {
                        this.editRenkoConfigDialog = false;
                        this.getRenkoConfigs(this.renkoConfigsPageNo);
                        this.resetForm('editRenkoConfigForm');
                        this.editRenkoConfigStartDatetime = '2020-04-12T00:00:00.000Z';
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.editRenkoConfigLoading = false;
                    this.editRenkoConfigDialog = false;
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 显示编辑策略弹窗
            showEditStrategyDialog(strategy){
                // 将对象序列化再解析目的是为了复制一个对象
                this.editStrategyForm = JSON.parse(JSON.stringify(strategy));
                this.editStrategyDialog = true;
            },
            // 提交编辑策略
            submitEditStrategy(){
                this.editStrategyLoading = true;
                let api = config.baseUrl + '/strategy/private/edit';
                let param = {
                    id: this.editStrategyForm.id,
                    description: this.editStrategyForm.description
                };
                this.$http.put(api, param).then(response => {
                    let data = response.data;
                    this.editStrategyLoading = false;
                    if (data.code == 200) {
                        this.editStrategyDialog = false;
                        this.getStrategies();
                        this.resetForm('editStrategyForm');
                        if (this.selectedStrategy == 'RK') {
                            this.getRenkoSreategyNames();
                        }
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.editStrategyDialog = false;
                    this.editStrategyLoading = false;
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
             // 是否登录
            isLogin() {
                if (!this.user.token) {
                this.$router.push({ name: "signIn" });
                this.$store.dispatch("snackbarMessageHandler", this.$t("signals.signalSnackbarMessage"));
                }else{
                    this.$store.dispatch("mobileBtnPageHandler",3);
                }
                
            },
        }
    }
</script>
